import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>wilson404</h1>
        <p><a href="https://www.wilson404.com">私有网盘</a></p>
        <p><a href="http://blog.wilson404.com">博客</a></p>
      </header>
      <p><a href="http://www.beian.miit.gov.cn/?spm=a2c4g.11186623.2.15.36407dc6X4VjPy">沪ICP备17004334号-1</a></p>
      <div className="beian" >
        <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011002003022">
          <img src={require("./beian.png")} alt="beianlogo"/>
          <p>沪公网安备 31011002003022号</p>
        </a>
      </div>
    </div>
  );
}

export default App;
